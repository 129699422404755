// react | next
import Link from 'next/link';
import type { GetStaticProps, NextPage } from 'next';
// layout
import { PageLayout } from '@layouts/PageLayout';
// components
import { SearchHero } from '@components/Hero/Search/SearchHero';
import { CarriersGrid } from '@components/Blocks/Grid/CarriersGrid/CarriersGrid';
import { SearchBlock } from '@components/Blocks/Search/SearchBlock';
import { LocationsBlock } from '@components/Blocks/Locations/LocationsBlock';
import { MapBlock } from '@components/Blocks/Map/MapBlock';
// third party
import { sanity } from '@data/sanity-client';
// enums
import { EColorTypes } from '@enums/EColorTypes';
// interfaces
import type { Carrier } from '@interfaces/SanitySchema';
import type { ISeo } from '@interfaces/ISeo';
// styles
import styles from '@styles/pages/index.module.scss';
// data
import { data } from '@data/MenuItems';
import carriers from '@data/carriers.preval';
// utils
import { deleteUndefinedKeys } from '@utils/functions/deleteUndefinedKeys';

interface IHomePageProps {
	featuredCarriers: Carrier[];
	seo: ISeo;
}

export const getStaticProps: GetStaticProps<IHomePageProps> = async () => {
	const featuredCarriers = carriers.filter((carrier) => carrier.featuredCarrier);
	const [homePage] = await sanity.getAll('home')


	const seo = {
		title: homePage?.pageTitle,
		description: homePage?.pageDescription,
		image: homePage?.socialShareImage?.asset?._ref,
	};

	return {
		props: {
			featuredCarriers,
			seo: deleteUndefinedKeys(seo),
		},
	};
};

export const HomePage: NextPage<IHomePageProps> = ({ featuredCarriers, seo, }) => {
	const fill = false;


	return (
		<PageLayout layout={data} fill={fill} seo={seo}>
			<SearchHero
				background='/images/D18-home-header.jpg'
				// banner={{
				// 	content: (
				// 		<Link href='/apply-now'>
				// 			<a className='button button-secondary' title='Navigate to Main Application Page'>
				// 				Apply Now in Less Than a Minute!
				// 			</a>
				// 		</Link>
				// 	),
				// }}
				border={true}
				color={EColorTypes.QUATERNARY}
				fill={fill}
				gradient={true}
				action='/jobs'
			/>
			<CarriersGrid featuredCarriers={featuredCarriers} />
			<SearchBlock background='/images/home-calendar.png' action='/hiring-events' />
			<section>
				<div className='container'>
					<Link href='/locations'>
						<a className={styles.locations_link}>
							<h2 className={`heading-secondary ${styles.heading}`}>
								Locations <i className={`fas fa-arrow-right ${styles.icon}`}></i>
							</h2>
						</a>
					</Link>
					<LocationsBlock />
				</div>
			</section>
			<MapBlock />
		</PageLayout>
	);
};

export default HomePage;
