// react | next
import Image from 'next/image';
import Link from 'next/link';
// interface | types
import type { ICarrierCard } from './ICarrierCard';
// styles
import styles from './CarrierCard.module.scss';

export const CarrierCard = ({ image, heading, text, carrier, logo, slug }: ICarrierCard) => {
	return (
		<div className={styles.card}>
			<div className={styles.cardImage}>
				<Image src={image} layout='fill' objectFit='cover' objectPosition='top center' />
			</div>
			<div className={styles.cardContent}>
				<div className={styles.cardBody}>
					<h5 className={styles.cardHeading}>{heading}</h5>
					<div className={styles.cardText}>{text}</div>
				</div>
				<div className={styles.cardCta}>
					<Link href={`/jobs/${slug}`}>
						<a className={styles.cardLink} aria-label={`${heading} link`}>
							{`${carrier} Driving Jobs`} <i className='far fa-chevron-right'></i>
						</a>
					</Link>
					<div className={styles.cardLogo}>
						<Image src={logo} alt={`Carrier logo for ${carrier}`} layout='fill' objectFit='contain' />
					</div>
				</div>
			</div>
		</div>
	);
};
