// react | next
import Link from 'next/link';
// interface | types
import type { ISvg } from '../ISvg';
// styles
import styles from './InteractiveMap.module.scss';
// data
import { states } from '@utils/states';

export const InteractiveMap = ({ height }: ISvg) => {
	const visibleStates = states.filter((state) => state.svgPath);
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 959 593' width='100%' height={height}>
			<title>Interactive Map of the United States</title>
			<g className={styles.states}>
				{visibleStates.map(({ title, abbreviation, svgPath }) => (
					<Link href={`/jobs?state=${abbreviation}`} key={abbreviation}>
						<path className={abbreviation} d={svgPath}>
							<title>{title}</title>
						</path>
					</Link>
				))}
			</g>
		</svg>
	);
};
