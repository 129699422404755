// react | next
import { useRef, useEffect } from 'react';
// interface | types
import type { ISearchBlock } from './ISearchBlock';
// components
import { SearchInput } from '@components/Hero/Inputs/Search/SearchInput';
// enums
import { EColorTypes } from '@enums/EColorTypes';
// styles
import styles from './SearchBlock.module.scss';

export const SearchBlock = ({ background, action }: ISearchBlock) => {
	const backgroundRef = useRef(null);

	useEffect(() => {
		backgroundRef.current.style.setProperty('--background-image', `url('${background}')`);
	}, [background]);

	return (
		<section className={styles.block_wrapper} ref={backgroundRef}>
			<div className='container'>
				<div className={styles.block_content_first}>
					<h2 className={`heading-on-dark ${styles.heading}`}>
						Discover Upcoming <br />
						<span className='heading-secondary'>Hiring Events</span>
					</h2>
					<div className={styles.search_item}>
						<SearchInput color={EColorTypes.SECONDARY} action={action} />
					</div>
				</div>
			</div>
		</section>
	);
};
