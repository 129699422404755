// third-party
import type { PortableTextComponents } from '@portabletext/react';
import { PortableText } from '@portabletext/react';
// interface | types
import type { IRichTextBlock } from './IRichTextBlock';
// styles
import styles from './RichTextBlock.module.scss';
import { urlFor } from '@lib/sanity';
// import urlBuilder from '@sanity/image-url'
// import imageUrlBuilder from "@sanity/image-url";

export const RichTextBlock = ({ value }: IRichTextBlock) => {
	const components: PortableTextComponents = {
		types: {
				image: ({value}) => <img src={urlFor(value).url()} />,
				youtube: ({value}) =>
				<iframe width="100%" height="500" src={value.url.replace("watch?v=", "embed/").split('&')[0]} />,
			},
		block: {
			h2: ({ children }) => <h2 className={styles.h2}>{children}</h2>,
			h3: ({ children }) => <h3 className={styles.h3}>{children}</h3>,
			h4: ({ children }) => <h4 className={styles.h4}>{children}</h4>,
			p: ({ children }) => <p className={styles.p}>{children}</p>,
			block: ({ children }) => <blockquote className={styles.blockquote}>{children}</blockquote>,
		},
		marks: {
			em: ({ children }) => <em className={styles.em}>{children}</em>,
			s: ({ children }) => <s className={styles.strikethrough}>{children}</s>,
			link: ({ value, children }) => {
				const target = (value?.href || '').startsWith('http') ? '_blank' : undefined;
				return (
					<a href={value?.href} target={target} rel={target === '_blank' && 'noindex nofollow'}>
						{children}
					</a>
				);
			},
		},
		list: {
			bullet: ({ children }) => <ul className={styles.ul}>{children}</ul>,
		},
		listItem: {
			bullet: ({ children }) => <li className={styles.li}>{children}</li>,
		},
	};

	return <PortableText value={value} components={components}
	 />;
};
