// react | next
import Link from 'next/link';
import { useRouter } from 'next/router';
// components
import { Dropdown } from '@components/Blocks/Dropdown/Dropdown';
// utils
import { states } from '@utils/states';
// styles
import styles from './LocationsBlock.module.scss';

export const LocationsBlock = () => {
	const router = useRouter();
	const query = router.query;
	const options = states.map((state) => ({ label: state.title, value: state.abbreviation }));

	const handleStateSelect = (newState: string) => {
		router.push({
			pathname: '/jobs',
			query: {
				...query,
				state: newState,
			},
		});
	};

	return (
		<div>
			<div className={styles.locations_block}>
				<span className={styles.locations_label} id='select-location'>
					Select Location <i className='fas fa-chevron-down'></i>
				</span>
				<ul className={styles.locations_list} aria-labelledby='select-location'>
					{states?.map(({ title, abbreviation }) => (
						<li className={styles.location_list_item} key={title}>
							<Link href={`/jobs?state=${abbreviation}`}>
								<a className={styles.location_link} title={`Navigate to ${title}'s Filtered Jobs`}>
									{title}
								</a>
							</Link>
						</li>
					))}
				</ul>
			</div>
			<div className={styles.dropdown}>
				<Dropdown label='Select Location' options={options} onSelect={handleStateSelect} />
			</div>
		</div>
	);
};
