// components
import { InteractiveMap } from '@components/Svgs/InteractiveMap/InteractiveMap';
// styles
import styles from './MapBlock.module.scss';

export const MapBlock = () => {
	return (
		<section className={styles.block_wrapper}>
			<div className='container' aria-label="interactive map">
				<InteractiveMap height='593' />
			</div>
		</section>
	);
};
