// react | next
import { useRef, useEffect } from 'react';
// interface | types
import type { ISearchHero } from './ISearchHero';
// components
import { SearchInput } from '@components/Hero/Inputs/Search/SearchInput';
// styles
import styles from './SearchHero.module.scss';

export const SearchHero = ({ background, banner, border, color, fill, gradient, action }: ISearchHero) => {
	const backgroundRef = useRef(null);

	useEffect(() => {
		backgroundRef.current.style.setProperty('--background-image', `url('${background}')`);
	}, [background]);

	return (
		<>
			<div
				className={`
					${styles.hero_wrapper}
					${border && !banner ? `hero-border-${color}` : ''}
					${!fill ? styles.hero_fill_false : ''}
					${gradient ? styles.hero_gradient : ''}
				`}
				ref={backgroundRef}
			>
				<div className={`container ${styles.hero}`}>
					<div className={styles.hero_content}>
						<h1 className={`heading-on-dark ${styles.hero_content_heading}`}>
							Find Your Next <br />
							<span className={`heading-${color}`}>Driving Job</span> Today
						</h1>
						<SearchInput color={color} action={action} />
					</div>
				</div>
			</div>
			{banner && (
				<div className={`${styles.hero_banner} ${color ? `bg_${color}` : ''}`}>
					<div className='container'>{banner?.content}</div>
				</div>
			)}
		</>
	);
};
