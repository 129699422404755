// components
import { CarrierCard } from '@components/Cards/Carrier/CarrierCard';
import { RichTextBlock } from '@components/Blocks/RichText/RichTextBlock';
// sanity
import { urlFor } from '@lib/sanity';
// interface
import type { ICarrierGrid } from './ICarrierGrid';
// styles
import styles from './CarriersGrid.module.scss';

export const CarriersGrid = ({ featuredCarriers }: ICarrierGrid) => {
	return (
		<section>
			<div className='container'>
				<div className={styles.carriers_grid}>
					<div className={styles.block_content_first}>
						<h2 className='heading-secondary'>Featured Employers</h2>
					</div>
					<div className={styles.block_content_last}>
						<div className={styles.flex_section}>
							{featuredCarriers?.map(({ bannerImage, carrierName, about, monochromeLogo, slug }) => (
								<div className={styles.flex_item} key={carrierName}>
									<CarrierCard
										image={urlFor(bannerImage.asset._ref).url()}
										heading={`Drive with ${carrierName}`}
										text={<RichTextBlock value={about} />}
										carrier={carrierName}
										logo={urlFor(monochromeLogo.asset._ref).url()}
										slug={slug.current}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
