// next | react
import { useRouter } from 'next/router';
import type { FormEvent } from 'react';
// interface | types
import type { ISearchInput } from './ISearchInput';
// styles
import styles from './SearchInput.module.scss';

export const SearchInput = ({ color, action }: ISearchInput) => {
	const router = useRouter();

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const zip = e.currentTarget.zip.value;

		router.push({
			pathname: `${action}`,
			query: {
				...router.query,
				zip,
			},
		});
	};

	return (
		<form onSubmit={handleSubmit} action={action} className={styles.block_search_wrapper}>
			<div className={styles.search_input_wrapper}>
				<input
					className={`${styles.search_input} search-input-${color}`}
					type='text'
					name='zip'
					placeholder='Enter Your Zip Code'
				/>
				<i className='far fa-location-circle fa-lg'></i>
			</div>

			<button className={`button-${color} ${styles.search_button}`}>Search</button>
		</form>
	);
};
